<ng-container *ngIf="!rebrand; else rebrandHeader">
  <div class="sub-header">
    <div class="sub-header__heading text--center">
      <h1 class="margin--none">{{ product?.title }}</h1>
      <p *ngIf="product?.subtitle" class="sub-title">{{ product?.subtitle }}</p>
    </div>
    <div class="sub-header__product text--center" *ngIf="product?.brand">
      <h2 class="margin--none line-clamp heading__highlight">
        <span>{{ product?.brand }}</span> {{ product?.applianceName }}
      </h2>
    </div>
    <div
      class="sub-header__plan flex align--center justify--center"
      *ngIf="product?.planNumber"
    >
      <div class="sub-header__plan-wrapper">
        <div class="sub-header__plan-item" *ngIf="product?.planNumber">
          <strong>Plan number</strong>
          <span [dgxQmSanitise]="'encrypt'">{{ product?.planNumber }}</span>
        </div>
        <div
          class="spacer"
          *ngIf="product?.planNumber && product?.modelNumber"
        ></div>
        <div class="sub-header__plan-item" *ngIf="product?.modelNumber">
          <strong>Model number</strong>
          <span>{{ product?.modelNumber }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #rebrandHeader>
  <div class="rebrand">
    <h1 class="claims-heading">
      <ng-content select="[title]"></ng-content>
    </h1>
    <caption
      *ngIf="product?.caption"
      [ngClass]="{ 'one-line-text': product?.oneLineText }"
    >
      <ng-content select="[caption]"></ng-content>
    </caption>
  </div>
</ng-template>
